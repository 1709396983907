

































import { AsyncComponent } from 'vue'
import { Component, Prop, Ref } from 'vue-property-decorator'
import { Navigation, Pagination, Swiper, SwiperOptions } from 'swiper'

import { log } from '../../../../support'

import { AbstractModuleUiPresentation } from '../../_abstract'
import { TileModuleContent } from '../../Tile'

import {
  CarouselBulletColor,
  CarouselModuleContent,
  CarouselModuleVersion
} from '../Carousel.contracts'

import {
  carouselModuleVersionComponentRegistry,
  DEFAULT_SWIPER_OPTIONS
} from './Carousel.ui.config'

/**
 * Presentational component for the `CarouselModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<CarouselModuleUiPresentation>({
  name: 'CarouselModuleUiPresentation',
  mounted (): void {
    // For the unknown reasons, when initialised immediately, the `.swiper-pagination` element
    // won't receive the classes `.swiper-pagination-clickable` and `.swiper-pagination-bullets`.
    // There's probably some problem inside the `Pagination` component inside the Swiper itself,
    // or maybe I use it in a wrong way. Either way, 100 ms delay does to job and the component
    // renders correctly. If you know a better solution, please, feel free to improve this code.
    setTimeout(() => { this.initSwiper() }, 200)
  }
})
export class CarouselModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * @see CarouselModuleContent.slides
   */
  @Prop({ type: Array, required: true })
  public readonly slides!: CarouselModuleContent<TileModuleContent>['slides']

  /**
   * @see CarouselModuleContent.swiperOptions
   */
  @Prop({ type: Object, required: false })
  private readonly swiperOptions?: CarouselModuleContent<TileModuleContent>['swiperOptions']

  /**
   * Version of the module. Determines which Vue component
   * will be used to render each slide's contents.
   *
   * @see CarouselModuleVersion
   */
  @Prop({ type: String, required: true })
  private readonly version!: CarouselModuleVersion

  @Ref('swiperContainer')
  private readonly swiperContainerRef?: HTMLDivElement

  @Ref('swiperButtonNext')
  private readonly swiperButtonNextRef?: HTMLButtonElement

  @Ref('swiperButtonPrev')
  private readonly swiperButtonPrevRef?: HTMLButtonElement

  /**
   * Swiper instance.
   */
  private swiper: Swiper | null = null

  public initialHeight: number = 0

  public expandedSlides: number[] = []

  /**
   * Determines whether there are any pagination bullets to render.
   */
  public get hasPaginationBullets (): boolean {
    if (this.swiper === null) {
      return false
    }

    return this.swiper.pagination.bullets.length > 1
  }

  public get hasSlidesExpanded (): boolean {
    return this.expandedSlides.length > 0
  }

  /**
   * Determines whether slider has less slides than minimum to display.
   */
  public get hasLessThanMinimum (): boolean {
    return this.slides.length < 5
  }

  /**
   * Determines whether color for swiper bullets are different than 'primary' color
   */
  public get hasBulletColor (): boolean {
    if (!this.swiperOptions) {
      return false
    }

    return typeof this.swiperOptions.bulletColor !== 'undefined' && this.swiperOptions.bulletColor.length > 0 && this.swiperOptions.bulletColor !== CarouselBulletColor.Primary
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasSlides && this.hasSlideComponent
  }

  /**
   * Vue component that should be used to render the slide's contents.
   */
  public get slideComponent (): AsyncComponent | undefined {
    const component = carouselModuleVersionComponentRegistry[this.version]

    if (typeof component === 'undefined') {
      log(
        `CarouselModuleUiPresentation.slideComponent(): There's no Vue component associated with the [${this.version}] CarouselModuleVersion!`,
        'error'
      )
      return
    }

    return component
  }

  /**
   * Updates the height of the Swiper.
   */
  public updateSwiperHeight (index: number, behavior: 'expanded' | 'collapsed'): void {
    if (behavior === 'expanded') {
      if (!this.initialHeight) {
        if (this.swiper === null) {
          log('CarouselModuleUiPresentation.updateSwiperHeight(): [this.swiper] i [null]!', 'error')
          return
        }

        this.initialHeight = this.swiper.height
      }

      this.$nextTick(() => {
        this.expandedSlides.push(index)
      })
    } else if (behavior === 'collapsed') {
      // Because we set that updateAutoHeight take 300 seconds that's,
      // why we can't change list of expandedSlides before this action take place.
      // We need to wait until extended slide is rolled up
      // Otherwise, all slides will have height of last collapsed slide.
      setTimeout(() => {
        this.expandedSlides = this.expandedSlides.filter(item => item !== index)

        this.$nextTick(() => {
          if (this.swiper === null) {
            return
          }

          if (this.expandedSlides.length === 0 && this.initialHeight !== 0 && this.swiper.height !== this.initialHeight) {
            this.scrollTop()
          }
        })
      }, 350)
    }

    this.$nextTick(() => {
      if (this.swiper === null) {
        log('CarouselModuleUiPresentation.updateSwiperHeight(): [this.swiper] i [null]!', 'error')
        return
      }

      if (this.swiper.$wrapperEl) {
        this.swiper.$wrapperEl.css('height', '')
      }
      this.swiper.updateAutoHeight(300)
    })
  }

  /**
   * `SwiperOptions` object ready to be used with the `Swiper` class constructor.
   */
  private static get combinedSwiperOptions (): SwiperOptions {
    // FIXME: Currently, when we're sending the breakpoints data to the API, it's being implicitly
    //  transformed from the basic object (key-value pairs) to the array. That implicit
    //  transformation brakes the UI, so this feature has been temporarily disabled (until the API
    //  gets fixed).
    //
    // @see Carousel.form.vue:12
    //
    // ---
    //
    // let swiperOptions: SwiperOptions = DEFAULT_SWIPER_OPTIONS
    //
    // if (typeof this.swiperOptions !== 'undefined') {
    //   swiperOptions = {
    //     ...swiperOptions,
    //     ...this.swiperOptions
    //   }
    // }
    //
    // return swiperOptions

    return DEFAULT_SWIPER_OPTIONS
  }

  private checkButtons (): void {
    if (this.swiper === null) {
      return
    }

    const options = CarouselModuleUiPresentation.combinedSwiperOptions
    const currentBreakpoint = this.swiper.currentBreakpoint
    let slidesPerView

    if (typeof options.breakpoints !== 'undefined' && typeof options.breakpoints[currentBreakpoint].slidesPerGroup !== 'undefined') {
      slidesPerView = options.breakpoints[currentBreakpoint].slidesPerGroup
    }

    if (!slidesPerView) {
      slidesPerView = 4
    }

    if (this.slides.length <= slidesPerView) {
      this.hideButtons()
    }
  }

  /**
   * Determines whether the Vue component for the given version has been successfully resolved.
   */
  private get hasSlideComponent (): boolean {
    return typeof this.slideComponent !== 'undefined'
  }

  /**
   * Determines whether the component has been provided with the correct `slides` prop.
   */
  private get hasSlides (): boolean {
    return typeof this.slides !== 'undefined' &&
      Array.isArray(this.slides) &&
      this.slides.length > 0
  }

  private hideButtons (): void {
    if (!this.swiperButtonNextRef || !this.swiperButtonPrevRef) {
      return
    }
    this.swiperButtonNextRef.style.display = 'none'
    this.swiperButtonPrevRef.style.display = 'none'
  }

  /**
   * Initialises the Swiper.
   */
  private initSwiper (): void {
    if (typeof window === 'undefined' || typeof this.swiperContainerRef === 'undefined') {
      return
    }

    Swiper.use([Navigation, Pagination])

    this.swiper = new Swiper(this.swiperContainerRef, {
      ...CarouselModuleUiPresentation.combinedSwiperOptions,
      navigation: {
        nextEl: this.swiperButtonNextRef,
        prevEl: this.swiperButtonPrevRef
      }
    })

    setTimeout(() => {
      if (this.swiper !== null) {
        this.swiper.updateAutoHeight(300)
        this.checkButtons()
      }
    }, 300)
  }

  private scrollTop (): void {
    if (!window || !this.swiper) {
      return
    }

    window.scroll({
      top: window.scrollY - (this.swiper.height - this.initialHeight),
      behavior: 'smooth'
    })
  }
}

export default CarouselModuleUiPresentation
