// Copyright © 2022 Move Closer

import { AsyncComponent } from 'vue'
import { SwiperOptions } from 'swiper'

import { CarouselModuleBreakpoint, CarouselModuleVersion } from '../Carousel.contracts'

/**
 * Registry that binds the `CarouselModuleBreakpoint` with the applicable screen width.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const carouselModuleBreakpointScreenWidthRegistry: Record<CarouselModuleBreakpoint, number> = {
  [CarouselModuleBreakpoint.XS]: 0,
  [CarouselModuleBreakpoint.SM]: 576,
  [CarouselModuleBreakpoint.MD]: 768,
  [CarouselModuleBreakpoint.LG]: 992,
  [CarouselModuleBreakpoint.XL]: 1200
}

/**
 * Registry that binds the `CarouselModuleVersion` with the applicable Vue component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const carouselModuleVersionComponentRegistry: Record<CarouselModuleVersion, AsyncComponent> = {
  [CarouselModuleVersion.Tiles]: () => import(
    /* webpackChunkName: "modules/Carousel" */
    './partials/TileSlide/TileSlide.vue'
  )
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const DEFAULT_SWIPER_OPTIONS: SwiperOptions = {
  autoHeight: true,
  breakpoints: {
    [carouselModuleBreakpointScreenWidthRegistry[CarouselModuleBreakpoint.XS]]: {
      slidesPerView: 1,
      slidesPerGroup: 1
    },
    [carouselModuleBreakpointScreenWidthRegistry[CarouselModuleBreakpoint.MD]]: {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
    [carouselModuleBreakpointScreenWidthRegistry[CarouselModuleBreakpoint.LG]]: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    [carouselModuleBreakpointScreenWidthRegistry[CarouselModuleBreakpoint.XL]]: {
      slidesPerView: 5,
      slidesPerGroup: 5
    }
  },
  pagination: {
    renderBullet: (index, className) => {
      return `
        <li class="${className} position-relative">
           <button class="bullet-btn"><span class="sr-only">Slajd ${index + 1}</span></button>
        </li>
      `
    },
    clickable: true,
    el: '.swiper-pagination',
    type: 'bullets'
  },
  spaceBetween: 40,
  centerInsufficientSlides: true,
  observer: true
}
