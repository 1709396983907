










import { Component, Prop, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'
import { TileBehavior, TileIconColor, TileIconPlacement, TileProps } from '../../../../../molecules'
import { Heading } from '../../../../../../contracts'
import { Link } from '@movecloser/ui-core'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<TileSlide>({
  name: 'TileSlide',
  components: {
    TileModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/shared" */
      '../../../../Tile/ui/Tile.ui.presentation.vue'
    )
  }
})
export class TileSlide extends Vue {
  @Prop({ type: String, required: true })
  public readonly behavior!: TileBehavior

  @Prop({ type: String, required: false })
  public readonly body?: string

  @Prop({ type: Boolean, required: false, default: false })
  public readonly border!: boolean

  @Prop({ type: String, required: false })
  public readonly contentAlignment?: TileProps['contentAlignment']

  @Prop({ type: String, required: false })
  public readonly extendedBody?: string

  @Prop({ type: Object, required: true })
  public readonly heading!: Heading

  @Prop({ type: String, required: false })
  public readonly headingAlignment?: TileProps['headingAlignment']

  @Prop({ type: String, required: false })
  public readonly iconColor?: TileIconColor

  @Prop({ type: String, required: false })
  public readonly iconName?: string

  @Prop({ type: String, required: false, default: TileIconPlacement.Top })
  public readonly iconPlacement!: TileIconPlacement

  @Prop({ type: Object, required: false })
  public readonly link?: Link

  @Prop({ type: Boolean, required: false, default: false })
  public readonly linkAsButton!: boolean

  /**
   * @see CarouselModuleUiPresentation.updateSwiperHeight
   */
  @Prop({ type: Function, required: true })
  public readonly updateSwiperHeight!: (behavior: 'expanded' | 'collapsed') => void

  @Prop({ type: Number, required: false })
  public initialHeight?: number

  public isExpanded: boolean = false

  public onCollapse (): void {
    this.isExpanded = false

    this.updateSwiperHeight('collapsed')
  }

  public onExpand (): void {
    this.isExpanded = true

    this.updateSwiperHeight('expanded')
  }

  get styles (): AnyObject | undefined {
    return {}
    // if (this.isExpanded || !this.initialHeight) {
    //   return
    // }

    // return { height: `${this.initialHeight}px` }
  }
}

export default TileSlide
