








import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../_abstract'

import { CarouselModule, CarouselModuleVersion } from '../Carousel.contracts'

/**
 * Container component for the `CarouselModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<CarouselModuleUi>({
  name: 'CarouselModuleUi',
  components: {
    CarouselModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/Carousel" */
      './Carousel.ui.presentation.vue'
    )
  }
})
export class CarouselModuleUi extends AbstractModuleUi<CarouselModule> {
  /**
   * @inheritDoc
   */
  protected versionEnum = CarouselModuleVersion

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasContent && this.hasValidVersion
  }
}

export default CarouselModuleUi
